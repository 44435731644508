$.fn.extend({
    treed: function (collapse, localStorageKey, o) {
        let openedClass = 'fa-minus-circle';
        let closedClass = 'fa-plus-circle';
        if (typeof o != 'undefined') {
            if (typeof o.openedClass != 'undefined') {
                openedClass = o.openedClass;
            }
            if (typeof o.closedClass != 'undefined') {
                closedClass = o.closedClass;
            }
        }
        const tree = $(this);
        const treeState = getTreeState(tree, localStorageKey);
        tree.addClass('tree');
        tree.find('li').has('ul').each(collapse
            ? function () {
                const branch = $(this);
                const id = branch.children('ul:first').attr('id');
                const expanded = treeState[id] === true;
                branch.prepend(`<i class="indicator fas ${expanded ? openedClass : closedClass}"></i>`);
                branch.addClass('branch');
                if (!expanded) {
                    branch.children().children().toggle();
                }
            }
            : function () {
                const branch = $(this);
                branch.prepend(`<i class="indicator fas ${openedClass}"></i>`);
                branch.addClass('branch');
            });
        tree.find('.branch .indicator').each(function () {
            $(this).on('click', function (e) {
                if (this == e.target) {
                    const li = $(this).closest('li');
                    const icon = li.children('i:first');
                    const id = li.children('ul:first').attr('id');
                    icon.toggleClass(openedClass + ' ' + closedClass);
                    li.children().children().toggle();
                    treeState[id] = icon.hasClass(openedClass) ? true : undefined;
                    writeTreeState(treeState, localStorageKey);
                }
            });
        });
    }
});
function getTreeState(tree, localStorageKey) {
    if (localStorageKey === null || localStorageKey === undefined) {
        return {};
    }
    const ids = tree.find('li').has('ul').map(function () {
        return $(this).children('ul:first').attr('id');
    }).toArray();
    const state = JSON.parse(localStorage.getItem(localStorageKey)) || {};
    for (const id in state) {
        if (!ids.includes(id)) {
            state[id] = undefined;
        }
    }
    return state;
}
function writeTreeState(state, localStorageKey) {
    if (localStorageKey === null || localStorageKey === undefined) {
        return;
    }
    localStorage.setItem(localStorageKey, JSON.stringify(state));
}
