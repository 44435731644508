var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cloneDeep } from 'lodash';
import { arraysEqual, url } from './utils';
import { editionEnabled } from './setupEdition';
import { validateQuery } from './queryEditor';
import { initQueryBuilder, showQueryBuilder } from './queryBuilder';
const pageLengths = [25, 50, 100, 200];
function fixPageLength(pageLength) {
    if (pageLength == null)
        return pageLengths[0];
    for (let i = pageLengths.length - 1; i > 0; --i)
        if (pageLength >= pageLengths[i])
            return pageLengths[i];
    return pageLengths[0];
}
function makeQuery(pattern, query) {
    return (pattern == null || pattern === '')
        ? query
        : (query == null || query === '')
            ? `wp:${pattern}`
            : `wp:${pattern} and (${query})`;
}
function makeState(model) {
    var _a;
    const word = (_a = model.pagination) === null || _a === void 0 ? void 0 : _a.word;
    const params = {
        q: makeQuery(model.pattern, model.query),
        w: word,
        e: word !== undefined ? true : undefined,
        l: fixPageLength(model.limit),
        d: model.desc ? true : undefined,
        c: true
    };
    return {
        params: params,
        query: model.query,
        pattern: model.pattern,
        tagColumns: model.tagIds
    };
}
function urlFromState(state) {
    let _a = state.params, { q, c } = _a, data = __rest(_a, ["q", "c"]);
    return url(window.location.href, Object.assign(Object.assign({ q: state.query, p: state.pattern }, data), { t: state.tagColumns }));
}
export function setupWordList(languageId, model) {
    let first;
    let last;
    let pageEvent;
    let pageIndex = 0;
    let state = makeState(model);
    let table;
    let cachedTags = [];
    initQueryBuilder({
        queryBuilt: (query) => {
            const queryFld = document.getElementById('words-search-query');
            queryFld.value = query.trim();
            queryFld.dispatchEvent(new Event('search'));
        }
    });
    function tableApi() {
        return table.api();
    }
    function makeTagsReq(tagIds) {
        return __awaiter(this, void 0, void 0, function* () {
            if (tagIds == null || tagIds.length === 0) {
                return [];
            }
            const data = yield $.ajax({
                url: '/api/tags',
                type: 'GET',
                cache: true,
                data: { t: tagIds },
                dataType: 'json'
            });
            cachedTags = cloneDeep(data.tags);
            return cachedTags;
        });
    }
    function makeAllTagsReq() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield $.ajax({
                url: '/api/tags?l=500',
                type: 'GET',
                cache: true,
                dataType: 'json'
            });
            return data.tags;
        });
    }
    function makeSpecialDataReq() {
        return __awaiter(this, void 0, void 0, function* () {
            const tags = yield makeAllTagsReq();
            return { tags: tags };
        });
    }
    function makeTagColumnHeader(tag) {
        if (editionEnabled()) {
            if (tag.dataType === 1) {
                return `
<div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle lx-dropdown-tag" type="button"
          id="dropdownMenuButton${tag.id}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    ${tag.displayName}
  </button>
  <div class="dropdown-menu shadow-sm" aria-labelledby="dropdownMenuButton${tag.id}">
    <a class="dropdown-item" onclick="wordTagTagPage(${tag.id}, true)"><i class="far fa-check-square"></i>&nbsp;&nbsp;Tag&nbsp;page</a>
    <a class="dropdown-item" onclick="wordTagTagPage(${tag.id}, false)"><i class="far fa-square"></i>&nbsp;&nbsp;Untag&nbsp;page</a>
    <a class="dropdown-item" onclick="wordTagTagAll(${tag.id}, true)"><i class="fas fa-check-square"></i>&nbsp;&nbsp;Tag&nbsp;all</a>
    <a class="dropdown-item" onclick="wordTagTagAll(${tag.id}, false)"><i class="fas fa-square"></i>&nbsp;&nbsp;Untag&nbsp;all</a>
  </div>
</div>`;
            }
            else {
                return `
<div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle lx-dropdown-tag" type="button"
          id="dropdownMenuButton${tag.id}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    ${tag.displayName}
  </button>
  <div class="dropdown-menu shadow-sm" aria-labelledby="dropdownMenuButton${tag.id}">
    <a class="dropdown-item" onclick="wordTagTagPage(${tag.id}, false)"><i class="far fa-square"></i>&nbsp;&nbsp;Untag&nbsp;page</a>
    <a class="dropdown-item" onclick="wordTagTagAll(${tag.id}, false)"><i class="fas fa-square"></i>&nbsp;&nbsp;Untag&nbsp;all</a>
  </div>
</div>`;
            }
        }
        else {
            return `
<div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle lx-dropdown-tag disabled" type="button">
    ${tag.displayName}
  </button>
</div>`;
        }
    }
    window.wordTagTagPage = function (tagId, enable) {
        const rowIds = tableApi().rows().data().map(row => row.id).toArray();
        const inputs = rowIds.map(rowId => $(`input#check-${rowId}-${tagId}`));
        inputs.forEach(input => input.prop('disabled', true));
        $.ajax({
            url: '/api/wordtags',
            type: enable ? 'PUT' : 'DELETE',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ wordIds: rowIds, tagIds: [tagId] }),
            success: () => inputs.forEach(input => {
                input.prop('checked', enable);
                input.prop('disabled', false);
            })
        });
    };
    window.wordTagTagAll = function (tagId, enable) {
        const rowIds = tableApi().rows().data().map(row => row.id).toArray();
        const inputs = rowIds.map(rowId => $(`input#check-${rowId}-${tagId}`));
        inputs.forEach(input => input.prop('disabled', true));
        tableApi().processing(true);
        $.ajax({
            url: state.params.q == null
                ? `/api/wordtags/${languageId}`
                : `/api/wordtags/${languageId}?${$.param({ q: state.params.q })}`,
            type: enable ? 'PUT' : 'DELETE',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ tagIds: [tagId] }),
            success: () => {
                inputs.forEach(input => {
                    input.prop('checked', enable);
                    input.prop('disabled', false);
                });
                tableApi().processing(false);
            }
        });
    };
    function renderTagColumn(tag) {
        if (tag.dataType === 1) {
            if (editionEnabled()) {
                return (data, _, row) => `
<label class="lx-checkbox-container">
  <input type="checkbox" id="check-${row.id}-${tag.id}"${data ? ' checked' : ''} onInput="wordTagCheckbox(${row.id}, ${tag.id})">
  <span class="lx-checkbox"></span>
</label>`;
            }
            return (data, _, row) => `
<label class="lx-checkbox-container" style="pointer-events: none !important;">
  <input type="checkbox" id="check-${row.id}-${tag.id}"${data ? ' checked' : ''} style="pointer-events: none !important;">
  <span class="lx-checkbox" style="pointer-events: none !important;"></span>
</label>`;
        }
        return (data, _, row) => data;
    }
    function makeTagColumn(tag) {
        return {
            data: `tags${tag.id}`,
            title: tag.displayName,
            className: tag.dataType === 1 ? 'text-center' : 'text-left',
            render: renderTagColumn(tag)
        };
    }
    window.wordTagCheckbox = function (rowId, tagId) {
        const input = $(`input#check-${rowId}-${tagId}`);
        const checked = input.prop('checked');
        input.prop('disabled', true);
        $.ajax({
            url: '/api/wordtags',
            type: checked ? 'PUT' : 'DELETE',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ wordIds: [rowId], tagIds: [tagId] }),
            success: () => input.prop('disabled', false)
        });
    };
    function convertWordResponse(words, tags) {
        return words.map(word => {
            const tagMap = Object.fromEntries(tags.map(tag => {
                let value;
                if (tag.dataType === 1) {
                    value = word.tags.some(wt => wt.tagId === tag.id);
                }
                else {
                    const wt = word.tags.find(wt => wt.tagId === tag.id);
                    value = wt == null ? '' : wt.text;
                }
                return [`tags${tag.id}`, value];
            }));
            return Object.assign({ id: word.id, value: word.value }, tagMap);
        });
    }
    function initSelectTagsModal() {
        $('#select-tags-modal').on('show.bs.modal', function () {
            $('#listbox-available-tags').on('dblclick', 'option', function () {
                $('#listbox-displayed-tags').append($(this));
            });
            $('#listbox-displayed-tags').on('dblclick', 'option', function () {
                $('#listbox-available-tags').append($(this));
            });
            document.getElementById('btn-apply-tags').addEventListener('click', () => {
                const tagIds = $('#listbox-displayed-tags option')
                    .map((_, domElement) => parseInt(domElement.value))
                    .toArray();
                $('#select-tags-modal').modal('hide');
                const prevTagColumns = state.tagColumns;
                state.tagColumns = tagIds;
                if (table == null || arraysEqual(prevTagColumns, tagIds))
                    return;
                const shrink = prevTagColumns.length > tagIds.length;
                const selector = $('#words-search-result');
                const pageLength = state.params.l;
                const tagsReq = makeTagsReq(state.tagColumns);
                tagsReq.then(tags => {
                    tableApi().destroy();
                    if (shrink)
                        $('#words-search-result').empty();
                    table = makeDatatable(selector, pageLength, tags);
                    pageEvent = 'columns';
                    tableApi().ajax.reload();
                });
            });
        });
    }
    function showSelectTagsModal() {
        makeAllTagsReq().then(tags => {
            const listboxAvailable = $('#listbox-available-tags');
            const listboxDisplayed = $('#listbox-displayed-tags');
            listboxAvailable.empty();
            listboxDisplayed.empty();
            tags.forEach(tag => {
                const option = $(`<option value="${tag.id}">${tag.displayName}</option>`);
                const listbox = state.tagColumns.includes(tag.id) ? listboxDisplayed : listboxAvailable;
                listbox.append(option);
            });
            $('#select-tags-modal').modal('show');
        });
    }
    function makeFilterControl() {
        const pattern = state.pattern == null ? '' : state.pattern;
        const query = state.query == null ? '' : state.query;
        return `
<input type="search" class="form-control form-control-sm"
       id="words-search-pattern" placeholder="" value="${pattern}">
<input type="search" class="form-control form-control-sm"
       id="words-search-query" placeholder="" value="${query}" hidden aria-hidden="true">
<div class="input-group-append">
  <button class="btn btn-primary btn-sm" type="button" id="words-search-filter-filter-button">
    <i class="fas fa-filter fa-sm"></i>
  </button>
  <button class="btn btn-primary btn-sm" type="button" id="words-search-filter-apply-button">
    <i class="fas fa-search fa-sm"></i>
  </button>
</div>`;
    }
    function makePageLengthControl() {
        return `<select name="words-search-result_length"
        aria-controls="words-search-result"
        class="custom-select custom-select-sm form-control form-control-sm"
        id="dt-length-0">`
            + pageLengths.map(length => `<option value="${length}"${length === state.params.l ? "selected" : ""}>${length}</option>`)
                .join()
            + `</select><label for="dt-length-0"> entries per page</label>`;
    }
    function resetScroll() {
        $('#card-words .dt-scroll-body').scrollTop(0);
    }
    window.letterLinkClick = function (letter) {
        state.params = {
            q: state.params.q,
            w: letter,
            e: undefined,
            l: state.params.l,
            d: undefined,
            c: true
        };
        history.pushState(state, '', urlFromState(state));
        tableApi().ajax.reload(resetScroll);
    };
    function makeLetterLinks() {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        const links = letters.map(letter => `<a class="btn btn-outline-secondary lx-btn-letter" onclick="letterLinkClick('${letter}')">${letter}</a>`);
        return links.join('&nbsp;');
    }
    window.remakeDatatable = () => {
        if (table != null) {
            tableApi().destroy();
            table = makeDatatable($('#words-search-result'), state.params.l, cachedTags);
            tableApi().ajax.reload();
        }
    };
    function validatePatternField(patternField, button) {
        var _a;
        const p = ((_a = patternField.value) !== null && _a !== void 0 ? _a : '').trim();
        const q = p !== '' ? `wp:${p}` : '';
        const valid = q === '' || validateQuery(q);
        patternField.classList.toggle('input-validation-error', !valid);
        patternField.setCustomValidity(valid ? '' : 'Invalid query!');
        button.disabled = !valid;
        return valid;
    }
    function validateQueryField(queryField, button) {
        var _a;
        const q = ((_a = queryField.value) !== null && _a !== void 0 ? _a : '').trim();
        const valid = q === '' || validateQuery(q);
        queryField.classList.toggle('input-validation-error', !valid);
        queryField.setCustomValidity(valid ? '' : 'Invalid query!');
        button.disabled = !valid;
        return valid;
    }
    function updateFromQueryField(patternField, queryField) {
        var _a, _b;
        const p = ((_a = patternField.value) !== null && _a !== void 0 ? _a : '').trim();
        const q = ((_b = queryField.value) !== null && _b !== void 0 ? _b : '').trim();
        const query = makeQuery(p, q);
        state.query = q === '' ? undefined : q;
        state.pattern = p === '' ? undefined : p;
        state.params.q = query;
        state.params.w = undefined;
        pageEvent = 'query';
        tableApi().ajax.reload(resetScroll);
    }
    function exportCsv() {
        const url = new URL(`/api/words/download/${languageId}`, window.location.origin);
        if (state.params.q)
            url.searchParams.append('q', state.params.q);
        for (const tagId of state.tagColumns) {
            url.searchParams.append('t[]', tagId.toString());
        }
        const a = document.createElement('a');
        a.href = url.href;
        a.click();
    }
    function makeDatatable(selector, pageLength, tags) {
        return selector.dataTable({
            pagingType: 'full',
            lengthChange: false,
            pageLength: pageLength,
            scrollY: 'calc(100vh - 20.5rem)',
            scrollCollapse: true,
            serverSide: true,
            processing: true,
            columns: [
                { data: 'value', title: 'Word' }
            ].concat(tags.map(makeTagColumn)),
            altEditor: true,
            layout: {
                topStart: {
                    div: {
                        id: 'input-group-words-search',
                        className: 'input-group',
                        html: makeFilterControl()
                    },
                    buttons: [
                        { name: 'tags', text: '<i class="fas fa-fw fa-tags fa-sm"></i>', action: showSelectTagsModal },
                        { name: 'refresh', text: '<i class="fas fa-fw fa-sync-alt fa-sm"></i>' },
                        'spacer',
                        { name: 'export', text: '<i class="fas fa-fw fa-file-download fa-sm"></i>', action: exportCsv }
                    ]
                },
                topEnd: {
                    div: {
                        className: 'dt-length',
                        html: makePageLengthControl()
                    }
                },
                bottomStart: {
                    div: {
                        html: makeLetterLinks()
                    }
                }
            },
            ajax: {
                url: `/api/words/${languageId}`,
                type: 'GET',
                cache: true,
                data: () => {
                    $('.dt-paging-button').addClass('disabled');
                    let push = false;
                    switch (pageEvent) {
                        case 'first':
                            push = true;
                            state.params.w = undefined;
                            state.params.e = undefined;
                            state.params.d = undefined;
                            break;
                        case 'previous':
                            push = true;
                            state.params.w = first;
                            state.params.e = first != null ? true : undefined;
                            state.params.d = true;
                            break;
                        case 'next':
                            push = true;
                            state.params.w = last;
                            state.params.e = last != null ? true : undefined;
                            state.params.d = undefined;
                            break;
                        case 'last':
                            push = true;
                            state.params.w = undefined;
                            state.params.e = undefined;
                            state.params.d = true;
                            break;
                        case 'columns':
                        case 'limit':
                        case 'query':
                            push = true;
                            break;
                    }
                    if (push) {
                        history.pushState(state, '', urlFromState(state));
                    }
                    pageEvent = undefined;
                    return Object.assign(Object.assign({}, state.params), { t: state.tagColumns });
                },
                dataFilter: response => {
                    const json = JSON.parse(response);
                    const words = json.words;
                    const prev = json.prev;
                    const next = json.next;
                    $('#card-words-text').text(`Words - ${json.count}`);
                    if (words.length > 0) {
                        first = words[0].value;
                        last = words[words.length - 1].value;
                    }
                    const count = (!prev && !next) ? pageLength : (pageLength * 5);
                    pageIndex = prev ? (next ? 2 : 4) : 0;
                    return JSON.stringify({
                        recordsTotal: count,
                        recordsFiltered: count,
                        data: convertWordResponse(words, tags)
                    });
                }
            },
            preDrawCallback: settings => {
                const api = new $.fn.dataTable.Api(settings);
                api.page(pageIndex);
                tags.forEach((tag, index) => api.column(index + 1).header().innerHTML = makeTagColumnHeader(tag));
            },
            initComplete: () => {
                document.getElementById('dt-length-0').addEventListener('change', e => {
                    state.params.l = parseInt(e.currentTarget.value);
                    pageEvent = 'limit';
                    tableApi().ajax.reload(resetScroll);
                });
                const patternFld = document.getElementById('words-search-pattern');
                const queryFld = document.getElementById('words-search-query');
                const applyBtn = document.getElementById('words-search-filter-apply-button');
                const filterBtn = document.getElementById('words-search-filter-filter-button');
                patternFld.addEventListener('change', _ => validatePatternField(patternFld, applyBtn));
                queryFld.addEventListener('change', _ => validateQueryField(queryFld, applyBtn));
                patternFld.addEventListener('search', _ => {
                    if (validatePatternField(patternFld, applyBtn) && validateQueryField(queryFld, applyBtn))
                        updateFromQueryField(patternFld, queryFld);
                });
                queryFld.addEventListener('search', _ => {
                    if (validatePatternField(patternFld, applyBtn) && validateQueryField(queryFld, applyBtn))
                        updateFromQueryField(patternFld, queryFld);
                });
                applyBtn.addEventListener('click', _ => updateFromQueryField(patternFld, queryFld));
                filterBtn.addEventListener('click', _ => showQueryBuilder($('#input-group-words-search'), $('#card-words'), makeSpecialDataReq()));
            }
        });
    }
    window.addEventListener('popstate', e => {
        first = undefined;
        last = undefined;
        pageEvent = undefined;
        pageIndex = 0;
        const prevState = state;
        state = e.state !== null ? e.state : makeState(model);
        if (table != null) {
            if (!arraysEqual(prevState.tagColumns, state.tagColumns)) {
                const shrink = prevState.tagColumns.length > state.tagColumns.length;
                const selector = $('#words-search-result');
                const pageLength = state.params.l;
                const tagsReq = makeTagsReq(state.tagColumns);
                tagsReq.then(tags => {
                    tableApi().destroy();
                    if (shrink)
                        selector.empty();
                    table = makeDatatable(selector, pageLength, tags);
                    tableApi().ajax.reload(resetScroll);
                });
            }
            else {
                $('#dt-length-0').val(state.params.l);
                $('#words-search-pattern').val(state.pattern);
                $('#words-search-query').val(state.query);
                tableApi().ajax.reload(resetScroll);
            }
        }
    });
    const pageLength = fixPageLength(model.limit);
    const tagsReq = makeTagsReq(model.tagIds);
    jQuery(() => {
        initSelectTagsModal();
        document.addEventListener('click', e => {
            if ($(e.target).hasClass('page-link')) {
                pageEvent = e.target.getAttribute('data-dt-idx');
                tableApi().one('draw', resetScroll);
            }
        }, true);
        tagsReq.then(tags => table = makeDatatable($('#words-search-result'), pageLength, tags));
    });
}
